// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import ImageAndWords from 'components/Contentful/ImageAndWords'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen Events"
        meta={[
          { name: 'description', content: 'Wollen Sie an Anlässen der Heizungsmacher AG teilnehmen? Erfahren Sie hier was gerade ansteht. Gleich reinschauen und Datum reservieren!' },
          { name: 'keywords', content: 'Anlässe, Events' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='36n1qKoMEBlQR96enhGJTZ-jnaerRpnyfHYZZVWMtuv7'
          id='events-der-heizungsmacher'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "jnaerRpnyfHYZZVWMtuv7",
    "updatedAt": "2024-01-25T09:50:48.883Z",
    "title": "Events der Heizungsmacher",
    "size": "Groß",
    "teaser": "Haben Sie schon einmal live erlebt, wie eine installierte Wärmepumpe oder Photovoltaik-Analge funktioniert? Wissen Sie wie laut eine Wärmepumpe ist? Was macht eigentlich der Wechselrichter? An unseren Anlässen stehen Ihnen unsere Experten mit Rat und Tat zur Seite. Erfahren Sie hier, welche Events anstehen.",
    "image": {
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4WDlklDFyWskv52tIG7SPb/594ad997653052824e2aa29f9a825bc4/Stiebel_Eltron_W__rmepumpe_WPL_Hero.jpg"
    },
    "anchorName": "events-der-heizungsmacher"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='36n1qKoMEBlQR96enhGJTZ-M1jKjIicCKZSLRdvNJ7T9'
          id='tage-der-offenen-waermepumpe'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "M1jKjIicCKZSLRdvNJ7T9",
    "updatedAt": "2024-01-25T09:49:36.911Z",
    "title": "Tage der offenen Wärmepumpe & Photovoltaik-Anlage",
    "text": "<p>An unseren <strong>Tagen der offenen Wärmepumpe &#x26; Photovoltaik-Anlage</strong> stellen wir Ihnen Umbauprojekte unserer geschätzten Kundinnen und Kunden vor Ort vor. Wir erklären Ihnen, wie eine Wärmepumpe oder ein Photovoltaik-Anlage funktionert, demonstrieren die Schallemissionen, zeigen Wechselrichter und stehen mit Expertenrat zur Seite.</p>\n<p>Erfahren Sie hier, wenn ein solche Tag ansteht. Reservieren Sie sich jetzt das Datum und schauen Sie ungeniert rein. Wir freuen uns auf Sie!</p>\n",
    "image": {
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/66LM5bC0tjmhRhMMIARQTd/029a4c387d3eb80b86fc9564eb97b901/Icon_Kunden_copy-01.svg"
    },
    "anchorName": "tage-der-offenen-waermepumpe",
    "link": {
        "type": "link",
        "id": "1jubVdtpIfmK7GKgdLgJ4A",
        "updatedAt": "2020-07-07T05:26:06.943Z",
        "label": "Daten & Orte",
        "page": {
            "slug": "events/tage-der-offenen-waermepumpe-photovoltaik-anlage"
        },
        "anchor": "Tag der offenen Wärmepumpe"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
